/** @jsxImportSource @emotion/react */
import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";

const PhotoLineSmall = () => {
  return (
    // <Stack sx={{ flexDirection: "row", width: "300%" }}>
    //   <Box sx={{}} className="embla__slide">
    //     <StaticImage
    //       alt=""
    //       src={`../../images/carouselImage3.png`}
    //       css={{ borderRadius: "120px" }}
    //     />
    //   </Box>
    //   <Box sx={{}} className="embla__slide">
    //     <StaticImage
    //       alt=""
    //       src={`../../images/carouselImage1.png`}
    //       css={{ borderRadius: "120px", width: "100%" }}
    //     />
    //   </Box>
    //   <Box sx={{}} className="embla__slide">
    //     <StaticImage
    //       alt=""
    //       src={`../../images/carouselShape1.svg`}
    //       css={{ borderRadius: "120px" }}
    //     />
    //   </Box>
    //   <Box sx={{}} className="embla__slide">
    //     <StaticImage
    //       alt=""
    //       src={`../../images/carouselImage2.png`}
    //       css={{ borderRadius: "120px" }}
    //     />
    //   </Box>
    //   <Box sx={{}} className="embla__slide">
    //     <StaticImage
    //       alt=""
    //       src={`../../images/carouselImage4.png`}
    //       css={{ borderRadius: "120px" }}
    //     />
    //   </Box>
    //   <Box sx={{}} className="embla__slide">
    //     <StaticImage
    //       alt=""
    //       src={`../../images/carouselShape2.svg`}
    //       css={{ borderRadius: "120px" }}
    //     />
    //   </Box>
    // </Stack>
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={0}
      loop
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
      css={{
        width: "100%",
        height: "100%",
        "> .swiper-wrapper": { alignItems: "center" },
        ".swiper-slide": {
          width: "auto",
        },
        "> .swiper-slide img": {
          display: "block",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      }}
    >
      <SwiperSlide>
        <StaticImage
          alt=""
          src={`../../images/carouselImage3.png`}
          imgStyle={{
            borderRadius: "120px",
            // width: "100%",
            display: "block",
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          alt=""
          src={`../../images/carouselImage1.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          alt=""
          src={`../../images/carouselShape1.svg`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          alt=""
          src={`../../images/carouselImage2.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          alt=""
          src={`../../images/carouselImage4.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          alt=""
          src={`../../images/carouselShape2.svg`}
          css={{ borderRadius: "120px" }}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default PhotoLineSmall;
