/** @jsxImportSource @emotion/react */
import { Box, CSSObject, Grid } from "@mui/material";
import gsap, { Linear, TweenLite, TimelineMax } from "gsap";

import React, { DOMElement } from "react";
import { useDimensions } from "./old/withDimensions";

const hashCode = (str: string) => {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

interface MabiParallaxTween {
  selector: string;
  from: TweenVars;
  to: TweenVars;
  delay?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

const MabiParallax = (props: {
  id: number | string;
  // start?: number; // percentage shift
  stepper?: number; // 0 --> 1 (percentage of refresh)
  duration?: number; // 0 --> 1 (percentage of height)
  children: any;
  tweens: MabiParallaxTween[];
  style?: CSSObject;
}) => {
  const [dimensions] = useDimensions();

  return <MabiParallaxComponent {...props} dimensions={dimensions} />;
};

export default MabiParallax;

interface ParallaxProps {
  id: string;
  dimensions: { width: number; height: number };
  tweens: MabiParallaxTween[];
  style?: CSSObject;
  className?: string;
}
class MabiParallaxComponent extends React.Component<any, ParallaxProps> {
  targetScroll = 0;
  duration = 0.5;
  stepper = 0.4;

  ref_out: any;
  serviceId?: string;
  postTweenTimeout: any;
  timeline: any;
  start?: number;

  componentDidMount() {
    this.serviceId =
      "sid-" +
      (typeof this.props.id === "number"
        ? this.props.id
        : hashCode(this.props.id));

    this.createTweens();
    window.addEventListener("scroll", this.onScroll);
    gsap.ticker.add(this.onTick);
  }

  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<ParallaxProps>,
    nextContext: any
  ): boolean {
    if (this.props.id !== nextProps.id) {
      return true;
    }

    const windowDimensionChanged =
      nextProps.dimensions.width !== this.props.dimensions.width ||
      nextProps.dimensions.height !== this.props.dimensions.height;

    const tweensChanged = areTweensDifferent(
      nextProps.tweens,
      this.props.tweens
    );

    return windowDimensionChanged || tweensChanged;
  }

  componentDidUpdate(prevProps: ParallaxProps) {
    if (this.postTweenTimeout) clearTimeout(this.postTweenTimeout);
    this.postTweenTimeout = setTimeout(() => {
      this.resetNonPersistingTweens();
      this.createTweens();
    }, 50); //? post by 500
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    gsap.ticker.add(this.onTick);
  }

  onScroll = () => {
    let scrollTop = Math.max(0, window.pageYOffset);
    this.targetScroll = this.props.dimensions.height
      ? scrollTop / this.props.dimensions.height
      : 0;
    // console.log(scrollTop, this.targetScroll);
  };

  onTick = () => {
    let progress = this.timeline.time();

    progress += (this.targetScroll - progress) * this.stepper;

    this.timeline.time(progress);
  };

  prevSelectors: string[] = [];

  resetNonPersistingTweens = () => {
    const newSelectors: string[] = this.props.tweens.map(
      (t: MabiParallaxTween) => t.selector
    );
    const toResetSelectors = this.prevSelectors.filter(
      (sel) => !newSelectors.includes(sel)
    );
    this.prevSelectors = newSelectors;

    gsap.set(toResetSelectors, { clearProps: "all" });
  };

  createTweens = () => {
    // console.log(this.props.id, "🟥", new Date().getTime());
    const { dimensions } = this.props;

    if (this.timeline) {
      // this.timeline.progress(1); //! add this if TweenMax.from
      this.timeline.kill();
    }
    this.timeline = new TimelineMax({
      paused: true,
      ease: Linear.easeNone,
    });

    var rect = { top: 0 };

    const ref_out = this.ref_out;
    if (ref_out && ref_out.getBoundingClientRect) {
      rect = ref_out.getBoundingClientRect();
    }

    this.start =
      // this.props.start +
      -1 + (rect.top + window.pageYOffset) / (dimensions.height || 1);

    const isMobile = dimensions.width <= 599.95;

    this.stepper = this.props.stepper || (isMobile ? 0.25 : 0.4);
    this.duration = this.props.duration || 0.5;

    this.props.tweens.forEach((t: MabiParallaxTween) => {
      if (t.minWidth && dimensions.width < t.minWidth) return;
      if (t.maxWidth && dimensions.width >= t.maxWidth) return;

      if (t.minHeight && dimensions.height < t.minHeight) return;
      if (t.maxHeight && dimensions.height >= t.maxHeight) return;

      this.timeline.fromTo(
        `.${this.serviceId} ${t.selector}`,
        this.duration,
        t.from,
        t.to,
        (this.start || 0) + (t.delay || 0)
      );
    });
  };

  render() {
    return (
      <div
        // item
        ref={(r) => (this.ref_out = r || this.ref_out)}
        className={this.serviceId + (" " + (this.props.className ?? ""))}
        css={this.props.style}
        // container
        // direction="row"
      >
        {this.props.children}
      </div>
    );
  }
}

//! tween vars

interface TweenVars extends AnimationVars {
  delay?: TweenValue;
  duration?: TweenValue;
  ease?: string | EaseFunction;
  endArray?: any[];
  immediateRender?: boolean;
  lazy?: boolean;
  keyframes?: TweenVars[] | object;
  onInterrupt?: Callback;
  onInterruptParams?: any[];
  overwrite?: "auto" | boolean;
  runBackwards?: boolean;
  stagger?: NumberValue | StaggerVars;
  startAt?: TweenVars;
  yoyoEase?: boolean | string | EaseFunction;
}

interface AnimationVars extends CallbackVars {
  [key: string]: any;
  data?: any;
  id?: string | number;
  inherit?: boolean;
  paused?: boolean;
  repeat?: number;
  repeatDelay?: number;
  repeatRefresh?: boolean;
  reversed?: boolean;
  yoyo?: boolean;
}

interface CallbackVars {
  callbackScope?: object;
  onComplete?: Callback;
  onCompleteParams?: any[];
  onRepeat?: Callback;
  onRepeatParams?: any[];
  onReverseComplete?: Callback;
  onReverseCompleteParams?: any[];
  onStart?: Callback;
  onStartParams?: any[];
  onUpdate?: Callback;
  onUpdateParams?: any[];
}

type Callback = (...args: any[]) => void | null;

interface EaseFunction {
  (progress: number): number;
}

interface StaggerVars extends CallbackVars, DistributeConfig {
  repeat?: number;
  repeatDelay?: number;
  yoyo?: boolean;
  yoyoEase?: boolean | string | EaseFunction;
}

interface DistributeConfig {
  amount?: number;
  axis?: "x" | "y";
  base?: number;
  each?: number;
  ease?: string | EaseFunction;
  from?:
    | "start"
    | "center"
    | "end"
    | "edges"
    | "random"
    | number
    | [number, number];
  grid?: "auto" | [number, number];
}

type NumberValue = number | FunctionBasedValue<number>;

type FunctionBasedValue<T> = (index: number, target: any, targets: any[]) => T;

type TweenValue = NumberValue | StringValue;

type StringValue = string | FunctionBasedValue<string>;

const areTweensDifferent = (a: MabiParallaxTween[], b: MabiParallaxTween[]) => {
  if (a.length !== b?.length) return true; // || JSON.stringify(a) !== JSON.stringify(b);
  for (let i = 0; i < a.length; i++) {
    const aTween = a[i];
    const bTween = b[i];
    if (bTween.selector !== aTween.selector) return true;
    if (tDifferences(aTween.from, bTween.from)) return true;
    if (tDifferences(aTween.to, bTween.to)) return true;
  }
  function tDifferences(a: any, b: any) {
    if (a === b) return false;
    if (typeof a !== typeof b) return true;
    if (typeof a === "object") {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) return true;
      for (let i = 0; i < aKeys.length; i++) {
        const key = aKeys[i];
        if (tDifferences(a[key], b[key])) return true;
      }
    }
    return false;
  }
  return false;
};
