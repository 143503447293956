function DreamersCardIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={139}
      height={139}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10_22)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.77 139.541c38.534 0 69.771-31.237 69.771-69.77C139.541 31.236 108.304 0 69.771 0 31.236 0 0 31.237 0 69.77c0 38.534 31.237 69.771 69.77 69.771zm0-34.615c19.416 0 35.156-15.74 35.156-35.156 0-19.415-15.74-35.155-35.156-35.155-19.415 0-35.155 15.74-35.155 35.155 0 19.416 15.74 35.156 35.155 35.156z"
          fill="#FF7A00"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10_22">
          <path fill="#fff" d="M0 0h139v139H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DreamersCardIcon2;
