function DreamersCardIcon5(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={139}
      height={139}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_11_35)">
        <path fill="#FF7A00" d="M0 0h68.82v68.82H0z" />
      </g>
      <g clipPath="url(#prefix__clip1_11_35)">
        <path fill="#57D6F9" d="M0 68.82h68.82v68.82H0z" />
        <path d="M68.82 136.779L0 68.819h68.82v67.96z" fill="#F9F5FF" />
      </g>
      <circle cx={103.66} cy={34.84} r={34.84} fill="#1E1E1E" />
      <circle cx={103.66} cy={34.84} r={20.216} fill="#FEFAF8" />
      <path fill="#FF7A00" d="M69.25 104.09h68.82v34.41H69.25z" />
      <path fill="#57D6F9" d="M69.25 69.68h68.82v34.41H69.25z" />
      <defs>
        <clipPath id="prefix__clip0_11_35">
          <path fill="#fff" d="M0 0h68.82v68.82H0z" />
        </clipPath>
        <clipPath id="prefix__clip1_11_35">
          <path
            fill="#fff"
            transform="translate(0 68.82)"
            d="M0 0h68.82v68.82H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DreamersCardIcon5;
