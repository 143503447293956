import { Box } from "@mui/material";
import React from "react";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";

const Title = () => {
  return (
    <Box>
      <CareerTypography variant="title1">
        {l_careers("openPositions.title")}
      </CareerTypography>
      <CareerTypography
        variant="body2"
        sx={{ marginBottom: { md: "60px", xs: "40px" } }}
      >
        {l_careers("openPositions.subtitle")}
      </CareerTypography>
    </Box>
  );
};

export default Title;
