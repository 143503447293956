function DreamersCardIcon6(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={139}
      height={139}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_11_51)">
        <path
          d="M69.986-.022l69.285 34.24-69.23 34.741L.271 34.353 69.985-.022z"
          fill="#57D6F9"
        />
        <path
          d="M69.986 68.96l69.285 35.087-69.356 34.375L.27 104.182l69.716-35.223z"
          fill="#FF7A00"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_11_51">
          <path fill="#fff" d="M0 0h139v139H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DreamersCardIcon6;
