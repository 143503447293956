import { Box, Grid, Stack } from "@mui/material";
import { ReactNode } from "react";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";
import DreamersCardIcon1 from "./CardIcons/DreamersCardIcon1";
import DreamersCardIcon2 from "./CardIcons/DreamerscardIcon2";
import DreamersCardIcon3 from "./CardIcons/DreamersCardIcon3";
import DreamersCardIcon4 from "./CardIcons/DreamersCardIcon4";
import DreamersCardIcon5 from "./CardIcons/DreamersCardIcon5";
import DreamersCardIcon6 from "./CardIcons/DreamersCardIcon6";

interface CardProps {
  title: string;
  description: string;
  icon: ReactNode;
}

const Card: React.FC<CardProps> = ({ title, description, icon }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box sx={{ marginBottom: { xs: "40px" } }}>
        <Box sx={{ marginBottom: "16px" }}>{icon}</Box>
        <CareerTypography variant="title2" sx={{ marginBottom: "8px" }}>
          {title}
        </CareerTypography>
        <CareerTypography variant="body2">{description}</CareerTypography>
      </Box>
    </Grid>
  );
};

const CardsSection = () => {
  const dreamersCards = [
    {
      title: l_careers("dreamersAndInnovators.card1Title"),
      description: l_careers("dreamersAndInnovators.card1Descr"),

      icon: <DreamersCardIcon1 />,
    },
    {
      title: l_careers("dreamersAndInnovators.card2Title"),
      description: l_careers("dreamersAndInnovators.card2Descr"),

      icon: <DreamersCardIcon2 />,
    },
    {
      title: l_careers("dreamersAndInnovators.card3Title"),
      description: l_careers("dreamersAndInnovators.card3Descr"),
      icon: <DreamersCardIcon3 />,
    },
    {
      title: l_careers("dreamersAndInnovators.card4Title"),
      description: l_careers("dreamersAndInnovators.card4Descr"),
      icon: <DreamersCardIcon4 />,
    },
    {
      title: l_careers("dreamersAndInnovators.card5Title"),
      description: l_careers("dreamersAndInnovators.card5Descr"),
      icon: <DreamersCardIcon5 />,
    },
    {
      title: l_careers("dreamersAndInnovators.card6Title"),
      description: l_careers("dreamersAndInnovators.card6Descr"),
      icon: <DreamersCardIcon6 />,
    },
  ];

  return (
    <Stack>
      <Grid container spacing={3}>
        {dreamersCards.map((card, index) => {
          return (
            <Card
              key={card.title}
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          );
        })}
      </Grid>
    </Stack>
  );
};

export default CardsSection;
