import { Box, Stack } from "@mui/material";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";

const Title = () => {
  return (
    <Stack
      sx={{
        padding: "24px",
        flex: 1,
        justifyContent: "center",
        gap: { md: "56px", xs: "16px" },
        boxSizing: "border-box",
      }}
    >
      <CareerTypography variant="overtitle">careers</CareerTypography>
      <CareerTypography
        sx={{
          fontSize: { lg: "96px", md: "74px", xs: "36px" },
          fontWeight: 700,
          lineHeight: { lg: "93px", md: "78px", xs: "40px" },
        }}
      >
        {l_careers("header.headerTitle")}
      </CareerTypography>

      <CareerTypography variant="body1" sx={{ letterSpacing: "-0.75px" }}>
        {l_careers("header.headerSub")}
      </CareerTypography>
    </Stack>
  );
};
export default Title;
