import { Box } from "@mui/material";
import OpenPositionsList from "./OpenPositionsList";
import Title from "./Title";

const OpenPositions = () => {
  return (
    <Box
      id="openPositions"
      sx={{
        width: "90%",
        padding: "24px",
        maxWidth: "1800px",
        margin: "auto",
        marginBottom: { md: "100px", xs: "40px" },
      }}
    >
      <Title />
      <OpenPositionsList />
    </Box>
  );
};

export default OpenPositions;
