/** @jsxImportSource @emotion/react */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../components/MabiloftSmoothScrollInterpolator";

// @ts-ignore
import scrollingImage1 from "../../images/scrollingImage1.jpg";
// @ts-ignore
import scrollingImage2 from "../../images/scrollingImage2.png";

const ScrollingPhotos = () => {
  const theme = useTheme();
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        marginTop: { md: "100px", xs: "60px" },
        marginBottom: { md: "120px", xs: "60px" },
      }}
    >
      <MabiParallax
        id="careers_ScrollingPhotos"
        duration={2}
        tweens={
          isOverMd
            ? [
                {
                  selector: ".scrollingImage1",
                  from: {
                    backgroundPosition: "50% 0%",
                    // y: "-100px",
                  },
                  to: {
                    backgroundPosition: "50% 100%",
                    // y: "100px",
                  },
                },
                {
                  selector: ".scrollingImage2",
                  from: {
                    backgroundPosition: "50% 0%",
                    // y: "-100px",
                  },
                  to: {
                    backgroundPosition: "50% 100%",
                    // y: "100px",
                  },
                },
              ]
            : [
                {
                  selector: ".scrollingImage1",
                  from: {
                    backgroundPosition: "50% 0%",
                    // y: "50px",
                  },
                  to: {
                    backgroundPosition: "50% 100%",
                    // y: "-50px",
                  },
                },
                {
                  selector: ".scrollingImage2",
                  from: {
                    backgroundPosition: "50% 0%",
                    // y: "50px",
                  },
                  to: {
                    backgroundPosition: "50% 100%",
                    // y: "-50px",
                  },
                },
              ]
        }
        style={{
          marginTop: isOverLg ? "500px" : isOverMd ? "300px" : "100px",
          // border: "1px solid green",
        }} // serve per imbrogliare parallax
      >
        <Box
          sx={{
            display: "flex",
            width: "90%",
            maxWidth: "1500px",

            margin: "auto",
            justifyContent: "space-between",
            // marginTop: "-500px",
            marginTop: { lg: "-500px", md: "-300px", xs: "-100px" },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${scrollingImage1})`,
              backgroundPosition: "50% 50%", // da modificare il secondo valore
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% auto",
              width: { xs: "40%", md: "40%" },
              borderRadius: "16px",
              // aspectRatio: "0.75 / 1",
            }}
            className="scrollingImage1"
          />
          <Box
            sx={{
              backgroundImage: `url(${scrollingImage2})`,
              width: { md: "50%", xs: "55%" },
              aspectRatio: "1 / 1",
              backgroundPosition: "50% 50%", // da modificare il secondo valore
              backgroundRepeat: "no-repeat",
              backgroundSize: "120% auto",
              borderRadius: "16px",
            }}
            className="scrollingImage2"
          />
        </Box>
      </MabiParallax>
    </Box>
  );
};
export default ScrollingPhotos;
