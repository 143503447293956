/** @jsxImportSource @emotion/react */
import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const PieceOfLineTwo = () => {
  return (
    <>
      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselImage5.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselImage6.png`}
          imgStyle={{ borderRadius: "120px", width: "100%" }}
        />
      </Box>
      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselShape2.svg`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>

      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselImage7.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselImage8.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselShape3.svg`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}}>
        <StaticImage
          alt=""
          src={`../../images/carouselShape1.svg`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
    </>
  );
};

const PhotoLineTwo = () => {
  return (
    <Box sx={{}}>
      <Stack
        sx={{
          display: { md: "flex", xs: "none" },
          flexDirection: "row",
          position: "relative",
          width: "calc(220% - 200px)",
          aspectRatio: "2700 / 180",
          maxWidth: "3712px",
          margin: "auto",
          alignItems: "center",
        }}
        className="photoLineTwo"
      >
        <PieceOfLineTwo />
        <PieceOfLineTwo />
      </Stack>
    </Box>
  );
};

export default PhotoLineTwo;
