/** @jsxImportSource @emotion/react */

import { useMediaQuery, useTheme } from "@mui/material";
// @ts-ignore
import video from "../../images/video.mp4";

const VideoSection = () => {
  const src = "/../../images/video.mp4";
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <video
      width={"90%"}
      css={{
        maxWidth: "1500px",
        borderRadius: "64px",
        margin: isOverMd ? "120px auto" : "60px auto",
      }}
      autoPlay
      loop
      muted
      playsInline={true}
    >
      <source src={video} type="video/mp4" />
    </video>
  );
};

export default VideoSection;
