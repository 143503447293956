import { Box } from "@mui/material";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";

const Title = () => {
  return (
    <Box sx={{ marginBottom: { md: "120px", xs: "40px" } }}>
      <CareerTypography
        variant="overtitle"
        sx={{ textAlign: "center", marginBottom: "40px" }}
      >
        {l_careers("dreamersAndInnovators.overtitle")}
      </CareerTypography>
      <CareerTypography
        variant="title1"
        sx={{ textAlign: { md: "center", xs: "left" } }}
      >
        <CareerTypography
          variant="span"
          sx={{
            fontFamily: "Metal",
            fontSize: { md: "60px", xs: "32px" },
            fontWeight: 400,
            letterSpacing: { md: -2.5, xs: -0.5 },
          }}
        >
          {l_careers("dreamersAndInnovators.dreamers")}
        </CareerTypography>
        ,{" "}
        <CareerTypography
          variant="span"
          sx={{
            fontFamily: "Fira code",
            fontWeight: 400,
            letterSpacing: { md: -2.5, xs: -0.5 },
          }}
        >
          {l_careers("dreamersAndInnovators.innovators")}
        </CareerTypography>
        {l_careers("dreamersAndInnovators.title")}
      </CareerTypography>
    </Box>
  );
};

export default Title;
