function DreamersCardIcon1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={139}
      height={139}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_9_19)">
        <path
          d="M0 139a69.772 69.772 0 0069.77-69.77A69.77 69.77 0 000-.54V139z"
          fill="#FF7A00"
        />
        <path d="M139-.54A69.77 69.77 0 10139 139V-.54z" fill="#57D6F9" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_9_19">
          <path fill="#fff" d="M0 0h139v139H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DreamersCardIcon1;
