import { Button, List, ListItem, Stack } from "@mui/material";
import { l_careers } from "../../locales";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import CareerTypography from "../../theme/CareerTypography";

interface OpenPositionListItemProps {
  jobTitle: string;
  yearsOfRequiredExperience: number;
  jobDescription: string;
  link: string;
}

const OpenPositionListItem: React.FC<OpenPositionListItemProps> = ({
  jobTitle,
  yearsOfRequiredExperience,
  jobDescription,
  link,
}) => {
  return (
    <ListItem
      sx={{
        backgroundColor: "white",
        padding: "32px",
        borderRadius: "30px",
        marginBottom: "20px",
        alignItems: { md: "center", xs: "baseline" },
        justifyContent: "space-between",
        flexDirection: { md: "row", xs: "column" },
      }}
    >
      <Stack
        sx={{
          flexDirection: "column",
          width: { md: "30%", xs: "100%" },
          gap: "8px",
        }}
      >
        <CareerTypography
          sx={{
            fontSize: { md: "28px", xs: "24px" },
            lineHeight: "32px",
            fontWeight: { md: 600, xs: 700 },
          }}
        >
          {jobTitle}
        </CareerTypography>
        {yearsOfRequiredExperience > 0 && (
          <CareerTypography
            variant="body2"
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              fontWeight: { md: 900, xs: 700 },
              color: "black",
            }}
          >
            {`${yearsOfRequiredExperience}+ ${l_careers(
              "openPositions.yearsOfExp"
            )}`}
          </CareerTypography>
        )}
      </Stack>

      <CareerTypography
        sx={{
          width: { md: "50%", xs: "100%" },
          padding: { md: "0 24px", xs: "24px 0" },
          marginRight: { md: "5%", xs: 0 },
        }}
      >
        {jobDescription}
      </CareerTypography>

      <Button
        variant="outlined"
        sx={{
          borderColor: "#E85322",
          backgroundColor: "transparent",
          borderRadius: "56px",
          fontSize: "16px",
          lineHeight: "16px",
          fontWeight: 700,
          padding: { md: "24px 68px", xs: "20px 24px" },
          borderWidth: "2px",
          width: { xs: "90%", md: "20%" },
          maxWidth: "300px",
          alignSelf: "center",

          ":hover": {
            borderColor: "inherit",
            backgroundColor: "#E8532212",
            borderWidth: "2px",
          },
        }}
        startIcon={<ButtonDotIcon />}
        href={`./${link}/`}
      >
        {l_careers("openPositions.buttonText")}
      </Button>
    </ListItem>
  );
};

const OpenPositionsList = () => {
  const openPositons = [
    // {
    //   jobTitle: l_careers("openPositions.jobTitleMob"),
    //   yearsOfRequiredExperience: 0,
    //   jobDescription: l_careers("openPositions.jobDescrMob"),
    //   link: "mobile-developer",
    // },
    // {
    //   jobTitle: l_careers("openPositions.jobTitleBac"),
    //   yearsOfRequiredExperience: 0,
    //   jobDescription: l_careers("openPositions.jobDescrBac"),
    //   link: "backend-developer",
    // },
    // {
    //   jobTitle: l_careers("openPositions.jobTitleBiz"),
    //   yearsOfRequiredExperience: 2,
    //   jobDescription: l_careers("openPositions.jobDescrBiz"),
    //   link: "business-developer",
    // },
    // {
    //   jobTitle: l_careers("openPositions.jobTitleBra"),
    //   yearsOfRequiredExperience: 1,
    //   jobDescription: l_careers("openPositions.jobDescrBra"),
    //   link: "brand-manager",
    // },
    // {
    //   jobTitle: l_careers("openPositions.jobTitleDes"),
    //   yearsOfRequiredExperience: 2,
    //   jobDescription: l_careers("openPositions.jobDescrDes"),
    //   link: "digital-product-designer",
    // },
    // {
    //   jobTitle: l_careers("openPositions.jobTitleDesJun"),
    //   yearsOfRequiredExperience: 0,
    //   jobDescription: l_careers("openPositions.jobDescrDes"),
    //   link: "junior-digital-product-designer",
    // },
    {
      jobTitle: l_careers("openPositions.jobTitleSocMed"),
      yearsOfRequiredExperience: 0,
      jobDescription: l_careers("openPositions.jobDescrSocMed"),
      link: "social-media-manager",
    },
    {
      jobTitle: l_careers("openPositions.jobTitle0"),
      yearsOfRequiredExperience: 0,
      jobDescription: l_careers("openPositions.jobDescr0"),
      link: "open-application",
    },
  ];

  return (
    <List sx={{}}>
      {openPositons.map((job, ind) => {
        return (
          <OpenPositionListItem
            key={job.jobTitle}
            jobTitle={job.jobTitle}
            jobDescription={job.jobDescription}
            yearsOfRequiredExperience={job.yearsOfRequiredExperience}
            link={job.link ?? ""}
          />
        );
      })}
    </List>
  );
};

export default OpenPositionsList;
