function DreamersCardIcon3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={139}
      height={139}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_11_26)">
        <circle cx={35.156} cy={35.156} r={35.156} fill="#FF7A00" />
        <circle cx={35.156} cy={103.844} r={35.156} fill="#FF7A00" />
        <circle cx={103.844} cy={35.156} r={35.156} fill="#57D6F9" />
        <circle cx={103.844} cy={103.844} r={35.156} fill="#FF7A00" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_11_26">
          <path fill="#fff" d="M0 0h139v139H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DreamersCardIcon3;
