/** @jsxImportSource @emotion/react */
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const PhotosSection = () => {
  return (
    <Box
      sx={{
        flex: 1,
        alignSelf: "center",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          width: "fit-content",
          marginLeft: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: { md: "block", xs: "none" },
            position: "absolute",
            top: "55%",
            left: "30%",
            width: "15%",
            zIndex: 5,
          }}
        >
          <StaticImage src={"../../images/headerIcon.png"} alt={""} css={{}} />
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <StaticImage
              src={"../../images/headerImage1.png"}
              alt={""}
              imgStyle={{
                width: "100%",
                borderRadius: "120px",
              }}
            />
          </Box>
          <Box sx={{}}>
            <StaticImage
              src={"../../images/headerImage2.png"}
              alt={""}
              imgStyle={{
                width: "100%",
                borderRadius: "120px",
                height: "100%",
              }}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              backgroundColor: "#57D6F9",
              // flex: 1,
              width: "240px",
              borderRadius: "120px",
            }}
          ></Box>
          <Box sx={{}}>
            <StaticImage
              src={"../../images/headerImage3.png"}
              alt={""}
              imgStyle={{ width: "100%", borderRadius: "120px" }}
            />
          </Box>
        </Box>

        <Box sx={{ display: { md: "flex", xs: "none" } }}>
          <Box sx={{}}>
            <StaticImage
              src={"../../images/headerImage4.png"}
              alt={""}
              imgStyle={{ width: "100%", borderRadius: "120px" }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FF7A00",
              width: "184px",
              borderRadius: "120px",
            }}
          ></Box>
          <Box
            sx={{
              backgroundColor: "#F1F4FF",
              width: "184px",
              borderRadius: "120px",
            }}
          ></Box>
        </Box>
      </Stack>
    </Box>
  );
};
export default PhotosSection;

{
  /* <Grid
        container
        sx={{ maxWidth: "1000px", margin: "auto", border: "1px solid red" }}
      >
        <Grid item xs={7}>
          <Box
            sx={{
              backgroundColor: "#F1F4FF",
              borderRadius: "120px",
              border: "1px solid green",
            }}
          >
            <StaticImage
              src={"../../images/headerPlaceholderImage1.png"}
              alt={""}
              css={{ width: "100%", borderRadius: "120px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={5} height={"fit-content"}>
          <Box sx={{ backgroundColor: "#F1F4FF" }}>
            <StaticImage
              src={"../../images/headerImage2.png"}
              alt={""}
              css={{ width: "100%", borderRadius: "120px", height: "100%" }}
            />
          </Box>
        </Grid>
      </Grid> */
}
