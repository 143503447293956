import { Box, Button } from "@mui/material";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import CareerTypography from "../../theme/CareerTypography";
import gsap, { Power3 } from "gsap";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { l_careers } from "../../locales";
gsap.registerPlugin(ScrollToPlugin);

const Description = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        width: { md: "55%", xs: "100%" },
        boxSizing: "border-box",
        display: { md: "block", xs: "flex" },
        flexDirection: "column",
      }}
    >
      <CareerTypography variant="body1">
        {l_careers("beLofters.subtitle1")}
        <br />
        {l_careers("beLofters.subtitle2")}
      </CareerTypography>
      <Button
        variant="outlined"
        sx={{
          marginTop: "32px",
          borderColor: "#E85322",
          backgroundColor: "transparent",
          borderRadius: "56px",
          fontSize: "16px",
          lineHeight: "16px",
          fontWeight: 700,
          padding: { md: "24px 68px", xs: "20px 24px" },
          borderWidth: "2px",
          width: { xs: "90%", md: "auto" },
          maxWidth: "400px",
          alignSelf: "center",
          ":hover": {
            borderColor: "inherit",
            backgroundColor: "#E8532212",
            borderWidth: "2px",
          },
        }}
        //href="#openPositions"
        startIcon={<ButtonDotIcon />}
        onClick={() => {
          // gsap.set(window, { scrollTo: "#openPositions" });
          gsap.to(window, {
            duration: 1,
            scrollTo: "#openPositions",
            ease: Power3.easeInOut,
          });
        }}
      >
        {l_careers("beLofters.buttonText")}
      </Button>
    </Box>
  );
};
export default Description;
