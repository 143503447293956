/** @jsxImportSource @emotion/react */
import { StaticImage } from "gatsby-plugin-image";
import { CSSProperties, useRef } from "react";
import { l_careers } from "../../locales";
import { LockScrollCarousel } from "../LockScrollCarousel.tsx/LockScrollCarousel";

//@ts-ignore
import img1 from "../../images/sliderImage1.png";
//@ts-ignore
import img2 from "../../images/sliderImage2.png";
//@ts-ignore
import img3 from "../../images/sliderImage3.png";
//@ts-ignore
import img4 from "../../images/sliderImage4.png";
//@ts-ignore
import img5 from "../../images/sliderImage5.png";
//@ts-ignore
import img6 from "../../images/sliderImage6.png";
//@ts-ignore
import img7 from "../../images/sliderImage7.png";

const OpinionsCards = () => {
  const parallaxRef = useRef<HTMLDivElement>(null);
  const imgFill = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as any;

  return (
    <div>
      {/* <h4 children="before horiz" css={{ textAlign: "center" }} /> */}

      <div css={{ position: "relative" }}>
        <LockScrollCarousel
          // items={[...new Array(7)].map((g, i) => ({
          //   title: `title ${i}`,
          //   description: `description ${i}`,
          //   image: (
          //     <div
          //       css={{
          //         width: "100%",
          //         height: "100%",
          //         backgroundColor: `rgb(${i % 2 == 0 ? 255 : 0}, ${(i * 50) % 250}, ${i * 20})`,
          //       }}
          //     />
          //   ),
          // }))}
          items={[
            {
              title: l_careers("opinionsCards.person1"),
              description: l_careers("opinionsCards.descr1"),
              image: img1,
            },
            {
              title: l_careers("opinionsCards.person2"),
              description: l_careers("opinionsCards.descr2"),
              image: img2,
            },
            {
              title: l_careers("opinionsCards.person3"),
              description: l_careers("opinionsCards.descr3"),
              image: img3,
            },
            {
              title: l_careers("opinionsCards.person4"),
              description: l_careers("opinionsCards.descr4"),
              image: img4,
            },
            {
              title: l_careers("opinionsCards.person6"),
              description: l_careers("opinionsCards.descr6"),
              image: img5,
            },
            {
              title: l_careers("opinionsCards.person5"),
              description: l_careers("opinionsCards.descr5"),
              image: img6,
            },
            {
              title: l_careers("opinionsCards.person7"),
              description: l_careers("opinionsCards.descr7"),
              image: img7,
            },
          ]}
        />
      </div>

      {/* <h4 children="after horiz" css={{ textAlign: "center" }} /> */}
    </div>
  );
};
export default OpinionsCards;

// const OpinionsCards = () => {
//   const parallaxRef = useRef<HTMLDivElement>(null);

//   return (
//     <div>
//       <h4 children="before horiz" css={{ textAlign: "center" }} />

//       <div css={{ position: "relative" }}>
//         <HorizontalLockScroll
//           onAttachToTimeline={(tl) => {
//             tl.to(parallaxRef.current, { x: 300 });
//           }}
//           pinStart="top 30%"
//           height={500}
//           horizontalItems={[...new Array(10)].map((g, i) => (
//             <div
//               key={i}
//               css={{
//                 minWidth: 100 + i * 100,
//                 // flexWrap: "wrap",
//                 // justifyContent: "center",
//                 // alignItems: "center",
//                 fontSize: 50,
//                 // border: "10px solid red",
//                 backgroundColor: `rgb(${i % 2 == 0 ? 255 : 0}, ${(i * 50) % 250}, ${i * 20})`,
//               }}
//               children={i}
//             />
//           ))}
//         />
//       </div>

//       <h4 children="after horiz" css={{ textAlign: "center" }} />
//     </div>
//   );
// };
// export default OpinionsCards;
