import { Box } from "@mui/material";
import CardsSection from "./CardsSection";
import Title from "./Title";

const DreamersAndInnovators = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        maxWidth: "1800px",
        width: "90%",
        margin: "auto",
        marginTop: { md: "200px", xs: "80px" },
      }}
    >
      <Title />
      <CardsSection />
    </Box>
  );
};
export default DreamersAndInnovators;
