import { Box, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../components/MabiloftSmoothScrollInterpolator";
import PhotoLineOne from "./PhotoLineOne";
import PhotoLineSmall from "./PhotoLineSmall";
import PhotoLineTwo from "./PhotoLineTwo";

const PhotosCarousel = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  //Line1 : 1856px

  return (
    <Box sx={{ marginTop: "120px", marginBottom: "120px" }}>
      {isOverMd ? (
        <MabiParallax
          id="rotatingPhotos"
          duration={5}
          tweens={
            isOverMd
              ? [
                  {
                    selector: ".photoLineOne",
                    from: {
                      x: "0%",
                    },
                    to: {
                      x: "-25%",
                    },
                  },
                  {
                    selector: ".photoLineTwo",
                    from: {
                      x: "-25%",
                    },
                    to: {
                      x: "0%",
                    },
                  },
                ]
              : []
          }
        >
          <PhotoLineOne />
          <PhotoLineTwo />
        </MabiParallax>
      ) : (
        <PhotoLineSmall />
      )}
    </Box>
  );
};
export default PhotosCarousel;
