import React, { useState, useEffect, useRef, useCallback } from 'react';

interface IDimensions {
	width: number;
	height: number;
}

export class DimensionsHandler {
	props: any;
	resizeTimeout: any;
	dimensions: IDimensions;

	constructor(props: any) {
		this.props = props;
		this.dimensions = {
			width: 0,
			height: 0,
		};
	}

	onResizeComplete = () => {
		// console.log(65, 1);

		//? compute
		const width =
			((typeof document !== `undefined` ? document : {}).documentElement || {})
				.clientWidth ||
			(typeof window !== `undefined` && window.innerWidth) ||
			0;
		const height =
			((typeof document !== `undefined` ? document : {}).documentElement || {})
				.clientHeight ||
			(typeof window !== `undefined` && window.innerHeight) ||
			0;

		// console.log(width, height);

		//? check
		if (width === this.dimensions.width && height === this.dimensions.height) {
			return;
		}

		this.dimensions = {
			width: width,
			height: height,
		};

		if (this.props.callback) this.props.callback(this.dimensions);
	};

	onResize = () => {
		const delay = this.props.delay || 300; //!wip customize delay
		// console.log(999, delay);
		if (delay) {
			if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
			this.resizeTimeout = setTimeout(this.onResizeComplete, delay);
		} else {
			this.onResizeComplete();
		}
	};

	mount() {
		window.addEventListener('resize', this.onResize);
		this.onResizeComplete();
	}

	unmount() {
		window.removeEventListener('resize', this.onResize);
	}
}

export const useDimensions = (
	props: { delay?: number; callback?: (dim: IDimensions) => void } = {}
) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	let resizeTimeout = useRef<any>();

	let dim = {
		width: dimensions.width,
		height: dimensions.height,
	};

	const onResizeComplete = useCallback(() => {
		//? compute
		const width =
			(typeof window !== `undefined` && window.innerWidth) || //!TODO - ho scambianto clientWidth con innerWidth per precisione, dato che non teneva conto della larghezza della scrollbar su desktop
			((typeof document !== `undefined` ? document : {}).documentElement || {})
				.clientWidth ||
			0;
		const height =
			((typeof document !== `undefined` ? document : {}).documentElement || {})
				.clientHeight ||
			(typeof window !== `undefined` && window.innerHeight) ||
			0;

		//? check
		if (width === dim.width && height === dim.height) {
			return;
		}

		dim.width = width;
		dim.height = height;

		if (props.callback) props.callback({ width, height });

		//? update
		setDimensions({
			width: width,
			height: height,
		});
	}, []);

	const onResize = () => {
		const delay = props.delay || 300; //!wip customize delay
		if (delay) {
			if (resizeTimeout.current) clearTimeout(resizeTimeout.current);
			resizeTimeout.current = setTimeout(onResizeComplete, delay);
		} else {
			onResizeComplete();
		}
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResizeComplete();
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return [dimensions];
};

export const withDimensions = (Component: any) => (props: any) => {
	const [dimensions] = useDimensions();
	return <Component {...props} dimensions={dimensions} />;
};
