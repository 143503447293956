import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Description from "./Description";
import Title from "./Title";

const BeLofters = () => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff", position: "relative" }}>
      <Box
        sx={{
          display: { md: "block", xs: "none" },
          position: "absolute",
          top: "-55px",
          right: "5%",
        }}
      >
        <StaticImage src="../../images/beLoftersDecoration.svg" alt="" />
      </Box>
      <Stack
        sx={{
          maxWidth: "1800px",
          width: "90%",
          margin: "auto",
          flexDirection: { md: "row", xs: "column" },
          paddingTop: { md: "95px", xs: "64px" },
          paddingBottom: { md: "95px", xs: "64px" },
          // border: "1px solid red",
        }}
      >
        <Title />
        <Description />
      </Stack>
    </Box>
  );
};
export default BeLofters;
