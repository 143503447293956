import { Box, Button } from "@mui/material";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";

const Title = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        width: { md: "45%", xs: "100%" },
        boxSizing: "border-box",
      }}
    >
      <CareerTypography variant="title1">
        {l_careers("beLofters.title")}
      </CareerTypography>
    </Box>
  );
};
export default Title;
