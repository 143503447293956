function DreamersCardIcon4(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={139}
      height={139}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_11_32)">
        <path
          d="M0 69.23a69.772 69.772 0 00119.106 49.335 69.774 69.774 0 0020.435-49.335H0z"
          fill="#0B0D18"
        />
        <circle cx={69.77} cy={34.615} r={35.156} fill="#FF7A00" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_11_32">
          <path fill="#fff" d="M0 0h139v139H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DreamersCardIcon4;
