import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";
import Careers from "../../features/careers";
import { l_careers } from "../../features/careers/locales";

export default Careers;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title="Careers"
      description={l_careers("seo.description")}
      headProps={headProps}
    />
  );
};
