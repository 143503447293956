import { Box } from "@mui/material";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";

const Title = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        margin: "auto",
        marginBottom: { md: "120px", xs: "60px" },
        marginTop: "60px",
        padding: "0 24px",
      }}
    >
      <CareerTypography variant="overtitle" sx={{ marginBottom: "40px" }}>
        {l_careers("onlyTheBest.overtitle")}
      </CareerTypography>
      <CareerTypography variant="title1">
        {l_careers("onlyTheBest.title")}
      </CareerTypography>
    </Box>
  );
};

export default Title;
